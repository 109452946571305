export default {
	name: 'OperateList',

	props: {
		dataList: {
			type: Array,
			default: () => []
		},
	},

	data() {
		return {};
	},

	methods: {
		clickItem(item) {
			this.$emit('onClickOperate', {
				...item
			});
		}
	}
};