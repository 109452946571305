import axios from 'axios';
import { eventBus } from '@/utils/event';
import { sendSMS } from '@/api/sms.js';

const showLog = localStorage.show_log === '1';

const instance = axios.create({
  timeout: 30000,
  headers: {}
});

export function network(opts) {
	const { method, params = {}, url, headers = {} } = opts;
	
	if (method === 'post') {
		return new Promise((resolve, reject) => {
			instance.post(url, {
			  ...params
			}, {
				headers
			}).then((res) => {
				const body = res.data;

				resolve(body);
			}).catch((err) => {
				// try {
				// 	sendSMS({
	   //        content: `接口${url}调用失败，错误信息:${JSON.stringify(err)}`
	   //      });
				// }
				// catch(e) {}

				if (err.response && err.response.status === 401) {
					reject({
						code: 1000,
						message: '登录过期，请重新登录'
					});
					eventBus.$emit('relogin');
					return;
				}

				if (err.response && err.response.status === 403) {
					reject({
						code: 1001,
						message: '无权限'
					});
					return;
				}

				if (err.response) {
					reject({
						code: 1002,
						message: err.response.statusText
					});
				}
				else {
					reject({
						code: 1002,
						message: err.message
					});
				}
			});
		});
	}

	if (method === 'get') {
		return new Promise((resolve, reject) => {
			instance.get(url, {
			  params,
			  headers
			}).then((res) => {
				const body = res.data;

				resolve(body);
			}).catch((err) => {
				// try {
				// 	sendSMS({
	   //        content: `接口${url}调用失败，错误信息:${JSON.stringify(err)}`
	   //      });
				// }
				// catch(e) {}

				if (err.response && err.response.status === 401) {
					reject({
						code: 1000,
						message: '登录过期，请重新登录'
					});
					eventBus.$emit('relogin');
					return;
				}

				if (err.response && err.response.status === 403) {
					reject({
						code: 1001,
						message: '无权限'
					});
					return;
				}

				if (err.response) {
					reject({
						code: 1002,
						message: err.response.statusText
					});
				}
				else {
					reject({
						code: 1002,
						message: err.message
					});
				}
			});
		});
	}
}
