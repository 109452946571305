var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login__back",on:{"click":_vm.back}},[_c('span',{staticClass:"login__back-icon"})]),(_vm.showPhone)?_c('div',{staticClass:"iphone",style:({width: _vm.iphoneWidth + 'px', height: _vm.iphoneHeight + 'px'})},[_c('div',{staticClass:"iphone__mask"}),_c('div',{staticClass:"iphone__screen",style:({width: _vm.screenWidth + 'px', height: _vm.screenHeight + 'px'})},[_c('div',{staticClass:"iphone__screen-mask",class:{'iphone__screen-mask--show': _vm.showPanel},style:({
        height: _vm.getPhoneSize(2133) + 'px',
        width: _vm.getPhoneSize(1290) + 'px',
        top: _vm.getPhoneSize(352) + 'px'
      })}),_c('div',{staticClass:"iphone__screen-pannel",style:({
        height: _vm.getPhoneSize(1000) + 'px',
        width: _vm.getPhoneSize(1290) + 'px',
        bottom: _vm.pannelBottom
      })},[_c('div',{staticClass:"iphone__screen-pannel-bar",style:({
          width: _vm.getPhoneSize(200) + 'px',
          height: _vm.getPhoneSize(20) + 'px',
          top: _vm.getPhoneSize(40) + 'px',
          'border-radius': _vm.getPhoneSize(20 / 2) + 'px'
        })}),_c('div',{staticClass:"iphone__screen-pannel-list",style:({paddingTop: _vm.getPhoneSize(100) + 'px'})},[_c('div',{staticClass:"iphone__screen-pannel-list-item",style:({
            height: _vm.getPhoneSize(200) + 'px'
          })},[_c('div',{staticClass:"iphone__screen-pannel-list-fakeitem",style:({
              height: _vm.getPhoneSize(180) + 'px',
              'border-radius': _vm.getPhoneSize(80 / 2) + 'px'
            })})]),_c('div',{staticClass:"iphone__screen-pannel-list-item",style:({
            height: _vm.getPhoneSize(200) + 'px'
          })},[_c('div',{staticClass:"iphone__screen-pannel-list-realitem",class:{'iphone__screen-pannel-list-realitem--anima': _vm.openanima},style:({
              height: _vm.getPhoneSize(180) + 'px',
              'border-radius': _vm.getPhoneSize(80 / 2) + 'px',
              'font-size': _vm.getPhoneSize(60) + 'px'
            })},[_vm._v("Open in external browser")])]),_c('div',{staticClass:"iphone__screen-pannel-list-item",style:({
            height: _vm.getPhoneSize(200) + 'px'
          })},[_c('div',{staticClass:"iphone__screen-pannel-list-fakeitem",style:({
              height: _vm.getPhoneSize(180) + 'px',
              'border-radius': _vm.getPhoneSize(80 / 2) + 'px'
            })})]),_c('div',{staticClass:"iphone__screen-pannel-list-item",style:({
            height: _vm.getPhoneSize(200) + 'px'
          })},[_c('div',{staticClass:"iphone__screen-pannel-list-fakeitem",style:({
              height: _vm.getPhoneSize(180) + 'px',
              'border-radius': _vm.getPhoneSize(80 / 2) + 'px'
            })})]),_c('div',{staticClass:"iphone__screen-pannel-list-item",style:({
            height: _vm.getPhoneSize(200) + 'px'
          })},[_c('div',{staticClass:"iphone__screen-pannel-list-fakeitem",style:({
              height: _vm.getPhoneSize(180) + 'px',
              'border-radius': _vm.getPhoneSize(80 / 2) + 'px'
            })})]),_c('div',{staticClass:"iphone__screen-pannel-list-item",style:({
            height: _vm.getPhoneSize(200) + 'px'
          })},[_c('div',{staticClass:"iphone__screen-pannel-list-fakeitem",style:({
              height: _vm.getPhoneSize(180) + 'px',
              'border-radius': _vm.getPhoneSize(80 / 2) + 'px'
            })})])])])]),_c('div',{staticClass:"iphone__screen-rt",class:{'iphone__screen-rt--anima': _vm.rtanima},style:({
      width: _vm.getPhoneSize(110) + 'px',
      height: _vm.getPhoneSize(110) + 'px',
      top: _vm.getPhoneSize(270) + 'px',
      right: _vm.getPhoneSize(120) + 'px',
    })}),_c('div',{staticClass:"iphone__screen-hand",style:({
      width: _vm.getPhoneSize(461) + 'px',
      height: _vm.getPhoneSize(756) + 'px',
      top: _vm.handStyle.top,
      right: _vm.handStyle.right,
      opacity: _vm.handStyle.opacity
    })}),_c('h3',[_vm._v("Open in external browser and Login")])]):(_vm.showCopyLink)?_c('div',{staticClass:"copy-link"},[_c('div',{staticClass:"copy-link__inner pos-center"},[_vm._m(0),_c('h1',[_vm._v("Login")]),_c('p',{staticClass:"login__slogan"},[_vm._v("Friend chat powered by AI")]),_c('div',{staticClass:"copy-link__input-wrap"},[_c('div',{staticClass:"copy-link__input-body"},[_c('div',{staticClass:"copy-link__input-box"},[_c('p',{staticClass:"copy-link__input ellipsis"},[_vm._v(_vm._s(_vm.copyLinkValue))])]),_c('div',{staticClass:"copy-link__copy-btn",on:{"click":_vm.copy}},[_vm._v("Copy")])])]),_vm._m(1),_vm._m(2),_vm._m(3)])]):[_c('div',{staticClass:"login__bg"}),_c('div',{staticClass:"login__mask"}),_c('div',{staticClass:"login__body"},[_vm._m(4),_c('h1',[_vm._v("Zelan")]),_c('p',{staticClass:"login__slogan"},[_vm._v("Friend chat powered by AI")]),_c('div',{staticClass:"login__methods"},[_c('div',{staticClass:"login__method",on:{"click":_vm.loginApple}},[_vm._m(5)]),_c('div',{staticClass:"login__method login__method--google",on:{"click":_vm.loginGoogle}},[_vm._m(6)])]),_vm._m(7),_vm._m(8)])]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login__appicon"},[_c('span',{staticClass:"login__appicon-inner"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"copy-link__tips"},[_vm._v("Please copy the link and Open the login page in "),_c('span',[_vm._v("external browser")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"login__privacy"},[_vm._v("By continuing，you agree to our "),_c('a',{attrs:{"href":"/web/terms_of_service"}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('a',{attrs:{"href":"/web/privacy_policy"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app_info"},[_c('a',{attrs:{"href":"/web/about"}},[_vm._v("About Us")]),_c('a',{attrs:{"href":"/web/contact"}},[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login__appicon"},[_c('span',{staticClass:"login__appicon-inner"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login__method-inner"},[_c('div',{staticClass:"login__icon login__discord-icon"}),_c('p',[_vm._v("Sign in with Apple")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login__method-inner"},[_c('span',{staticClass:"login__icon login__google-icon"}),_c('p',[_vm._v("Sign in with Google")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"login__privacy"},[_vm._v("By continuing，you agree to our "),_c('a',{attrs:{"href":"/web/terms_of_service"}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('a',{attrs:{"href":"/web/privacy_policy"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app_info"},[_c('a',{attrs:{"href":"/web/about"}},[_vm._v("About Us")]),_c('a',{attrs:{"href":"/web/contact"}},[_vm._v("Contact")])])
}]

export { render, staticRenderFns }