import store from '@/store';
import { network } from './network';
import { getBaseInviteCode } from '@/utils/util';

export async function report(opts) {
	let inviteCode = store.state.user.inviteCode;
	
	if (process.env.NODE_ENV === 'development') {
		return;
	}

	if (!inviteCode) {
		inviteCode = getBaseInviteCode();
	}

	const res = await network({
		url: 'https://report.x-matrix.top/api/zelan_report',
		method: 'post',
		params: {
			...opts,
			invite_code: inviteCode,
			extra_data: JSON.stringify({
				url: window.location.href
			})
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}