export default {
	name: 'Navigation',

	props: {
		title: {
			type: String,
			default: 'Page title'
		},

		customLeft: {
			type: Boolean,
			default: false
		},

		customTitle: {
			type: Boolean,
			default: false
		},

		showLeft: {
			type: Boolean,
			default: true
		}
	},

	data() {
		return {};
	},

	methods: {
		back() {
			this.$router.back();
		},
	}
}