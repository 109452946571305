import { render, staticRenderFns } from "./Task.vue?vue&type=template&id=255e0906&scoped=true"
import script from "./task.js?vue&type=script&lang=js&external"
export * from "./task.js?vue&type=script&lang=js&external"
import style0 from "./task.scss?vue&type=style&index=0&id=255e0906&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255e0906",
  null
  
)

export default component.exports