import { network } from './network';

export async function queryTopRoles(opts) {
	const res = await network({
		url: '/ai/api/web/v1/category/main',
		method: 'get',
		params: {
			...opts
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function queryTabRoles(opts) {
	const res = await network({
		url: '/ai/api/web/v1/category/character',
		method: 'get',
		params: {
			...opts
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function queryRoleDetail(opts) {
	const res = await network({
		url: '/ai/api/web/v1/role/info',
		method: 'get',
		params: {
			...opts
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function queryRoleCards(opts) {
	const res = await network({
		url: '/ai/api/web/v1/role/card/all',
		method: 'get',
		params: {
			...opts
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

