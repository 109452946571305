import { mixins } from '@/mixins';
import { report } from '@/api/report';

export default {
	name: 'OpenApp',
	mixins: [mixins],

	props: {
		bottom: {
			type: Number,
			default: 250
		}
	},

	data() {
		return {};
	},

	methods: {
		clickDownGuide() {
			report({
	      event_type: 'click',
	      event_value: 'click_down_guide'
	    });
	    this.goDownload();
		}
	}
};