export default {
	name: 'TabBar',

	props: {
		activeId: {
			type: String,
			default: 'home'
		}
	},

	data() {
		return {
			tabList: [
				{
					id: 'home',
					name: 'Home'
				},
				// {
				// 	id: 'search',
				// 	name: 'Search'
				// },
				{
					id: 'messages',
					name: 'Messages'
				},
				{
					id: 'you',
					name: 'You'
				}
			]
		}
	},

	methods: {
		clickTab(tabInfo) {
			this.$emit('onClickTab', {
				...tabInfo
			});
		}
	}
}