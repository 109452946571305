import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
import { report } from '@/api/report';
import { getStorageData } from '@/utils/util';
import { loginout, deleteAccount } from '@/api/login';
import Navigation from '@/components/Navigation/Navigation.vue';
import OperateList from '@/components/OperateList/OperateList.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';

export default {
	name: 'Setting',

	data() {
		return {
			operateDataList1: [
				{
					id: 'terms_of_service',
					name: 'Terms of service',
					icon: 'icon-terms-of-service',
					color: '#e4e5e7',
					leaf: false
				},

				{
					id: 'privacy_policy',
					name: 'Privacy Policy',
					icon: 'icon-privacy-policy',
					color: '#e4e5e7',
					leaf: false
				},

				{
					id: 'del_account',
					name: 'Delete account',
					icon: 'icon-del-account',
					color: '#e4e5e7',
					leaf: true
				}
			],

			operateDataList2: [
				{
					id: 'log_out',
					name: 'Log out',
					icon: 'icon-logout',
					color: '#ce5951',
					leaf: true
				}
			]
		};
	},

	computed: {
    ...mapGetters(['inviteCode', 'userToken']),
  },

	methods: {
		onClickOperate(item) {
			if (item.id === 'terms_of_service') {
				window.location.href = 'https://x-matrix.top/desktop/terms_of_service';
			}

			if (item.id === 'privacy_policy') {
				window.location.href = 'https://x-matrix.top/desktop/privacy_policy';
			}

			if (item.id === 'del_account') {
				this.$refs.delAccountdialog.show();
			}

			if (item.id === 'log_out') {
				this.signout();
			}
		},

		cancelDel() {
			this.$refs.delAccountdialog.hide();
		},

		clickDialogBtn(button) {
			if (button.id === 'cancel_btn') {
				this.$refs.delAccountdialog.hide();
			}

			if (button.id === 'delete_btn') {
				this.deleteAccount();
			}
		},

		async deleteAccount() {
			const inviteCode = this.inviteCode || '';
      const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });
      const userToken = this.userToken || '';
      const res = await deleteAccount({
        token: userToken
      });

      localStorage.setItem('user_token', '');
      this.$store.dispatch('updateUserToken', {
      	userToken: ''
      });
      this.$message({
        message: 'successful',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
      this.$store.dispatch('updateUserInfo');
      loading.close();
      this.$router.push({
        path: '/',
        query: {
        	invite_code: inviteCode,
        }
      });
		},

		async signout() {
			const inviteCode = this.inviteCode || '';
      const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });
      const userToken = this.userToken || '';
      const res = await loginout({
        token: userToken
      });

      localStorage.setItem('user_token', '');
      this.$store.dispatch('updateUserToken', {
      	userToken: ''
      });
      this.$message({
        message: 'successful',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
      this.$store.dispatch('updateUserInfo');
      loading.close();
      this.$router.push({
        path: '/',
        query: {
        	invite_code: inviteCode,
        	reload: '1'
        }
      });
    },
	},

	activated() {
		report({
      event_type: 'view',
      event_value: 'setting'
    });
	},

	components: {
		Navigation,
		OperateList,
		Dialog,
		OpenApp
	}
};
