import { mapGetters } from 'vuex';
import { getStorageData } from '@/utils/util';

export const tabDownloadPageMixin = {
  computed: {
    ...mapGetters(['inviteCode']),
  },
  
	methods: {
		async goDownload() {
      const inviteCode = this.inviteCode || '';

      this.$router.push({
        path: `/download`,
        query: {
          invite_code: inviteCode,
        }
      });
    },
	}
};