export function download() {
	try {
		const { VUE_APP_PLATFORM, VUE_APP_ENVIRONMENT } = window.ZELAN_ENV;

		if (VUE_APP_ENVIRONMENT !== 'online') {
			fbq('trackCustom', 'Download');
			return;
		}

		if (VUE_APP_PLATFORM === 'tiktok') {
			ttq.track('Download');
		}
	}
	catch(err) {
		console.log(err);
	}
}