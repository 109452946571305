import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
import { initializeApp } from "firebase/app";
import { loginWithGoogle, loginWithApple } from '@/api/login';
import { getUserInfo } from '@/api/user';
import { queryUrl, sleep, isInAppWebview, isTTSystem, isFBSystem, getBaseInviteCode } from '@/utils/util';
import { report } from '@/api/report';
import { getAuth, signInWithRedirect, getRedirectResult, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import firebase from '@/firebase';

const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
const rootFontSize = viewportWidth / 1125;
let iphoneHeight = document.documentElement.clientHeight - 200 * rootFontSize * 2;
let iphoneWidth = 1450 * iphoneHeight / 2936;
let screenHeight = iphoneHeight * 2796 / 2936;
let screenWidth = iphoneWidth * 1290 / 1450;

function getPhoneSize(size) {
	return size * iphoneWidth / 1450;
}

const handStart = {
	top: getPhoneSize(430) + 'px',
  right: getPhoneSize(-430) + 'px',
  opacity: 0
};
const handEnd = {
	top: getPhoneSize(330) + 'px',
  right: getPhoneSize(-230) + 'px',
  opacity: 1
};
const handMove = {
	top: getPhoneSize(2230) + 'px',
  right: getPhoneSize(100) + 'px',
  opacity: 1
};

export default {
	data() {
		return {
			iphoneHeight,
			iphoneWidth,
			screenHeight,
			screenWidth,
			rtanima: false,
			openanima: false,
			showPanel: false,
			handStyle: handStart,
			pannelBottom: getPhoneSize(-1000) + 'px',
			showPhone: isFBSystem(),
			showCopyLink: isTTSystem(),
			copyLinkValue: ''
		}
	},

	computed: {
    ...mapGetters(['inviteCode']),
  },

	methods: {
		async back() {
			const inviteCode = this.inviteCode || '';

			this.$router.push({
        path: '/',
        query: {
          invite_code: inviteCode,
        }
      });
		},

		async updateCopyLinkValue() {
			const inviteCode = this.inviteCode || '';

			this.copyLinkValue = `https://zelantk.the-path.top/web/?invite_code=${inviteCode}`;
		},

		step1() {
			this.handStyle = handEnd;
		},

		step2() {
			this.rtanima = true;
		},

		step3() {
			this.showPanel = true;
			this.pannelBottom = 0;
		},

		step4() {
			this.handStyle = handMove;
			this.rtanima = false;
		},

		step5() {
			this.openanima = true;
		},

		step6() {
			this.openanima = false;
			this.handStyle = handStart;
			this.showPanel = false;
			this.pannelBottom = getPhoneSize(-1000) + 'px';
		},

		getPhoneSize,

		copy() {
			const textarea = document.createElement('textarea');

			textarea.value = this.copyLinkValue;      
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      
      this.$message({
        message: 'Copy success',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
      report({
	      event_type: 'click',
	      event_value: 'copy_login_link'
	    });
		},

		async loginGoogle() {
			const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });

      report({
	      event_type: 'click',
	      event_value: 'google_login'
	    });

			try {
				const { redirect_url } = queryUrl(window.location.href);
				const provider = new GoogleAuthProvider();
				const authResult = await signInWithPopup(firebase.auth, provider);
				const user = authResult.user;
				const inviteCode = this.inviteCode || getBaseInviteCode();
	      const res = await loginWithGoogle({
	      	uid: user.uid,
	      	token: user.accessToken,
	      	inviteCode
	      });

	      if (res.statusCode !== 200) {
	      	this.$message({
			      message: 'login fail',
			      type: 'error',
			      showClose: true,
			      customClass: 'custom-message',
			    });
			    loading.close();
			    try {
			    	report({
				      event_type: 'click',
				      event_value: `google_login_fail: ${JSON.stringify(res)}`
				    });
			    }
			    catch(err) {}
			    return;
	      }

	      report({
		      event_type: 'click',
		      event_value: 'google_login_success'
		    });

	      localStorage.setItem('user_token', res.data);
	      this.$store.dispatch('updateUserToken', {
	      	userToken: res.data
	      });

	      if (redirect_url) {
	      	loading.close();
	        window.location.href = decodeURIComponent(redirect_url);
	        return;
	      }

	      await this.$store.dispatch('updateUserInfo');
	      this.$message({
		      message: 'successful',
		      type: 'success',
		      showClose: true,
		      customClass: 'custom-message',
		    });
	      this.$router.push({
	        path: '/',
	        query: {
	        	invite_code: inviteCode,
	        	reload: '1'
	        }
	      });
	      loading.close();
			}
			catch(err) {
				try {
					report({
			      event_type: 'click',
			      event_value: `google_auth_fail: ${JSON.stringify(err)}`
			    });
				}
				catch(e) {}
				loading.close();
			}
		},

		async loginApple() {
			const loading = Loading.service({
        fullscreen: true,
        customClass: 'app-loading',
        background: '#1c1d21'
      });

			report({
	      event_type: 'click',
	      event_value: 'apple_login'
	    });

			try {
				const { redirect_url } = queryUrl(window.location.href);
				const provider = new OAuthProvider('apple.com');
	      const authResult = await signInWithPopup(firebase.auth, provider);
				const user = authResult.user;
				const inviteCode = this.inviteCode || getBaseInviteCode();
	      const res = await loginWithApple({
	      	uid: user.uid,
	      	token: user.accessToken,
	      	inviteCode
	      });

	      if (res.statusCode !== 200) {
	      	this.$message({
			      message: 'login fail',
			      type: 'error',
			      showClose: true,
			      customClass: 'custom-message',
			    });
			    loading.close();
			    try {
			    	report({
				      event_type: 'click',
				      event_value: `apple_login_fail: ${JSON.stringify(res)}`
				    });
			    }
			    catch(err) {}
			    return;
	      }

	      report({
		      event_type: 'click',
		      event_value: 'apple_login_success'
		    });

	      localStorage.setItem('user_token', res.data);
	      this.$store.dispatch('updateUserToken', {
	      	userToken: res.data
	      });
	      this.$message({
		      message: 'successful',
		      type: 'success',
		      showClose: true,
		      customClass: 'custom-message',
		    });

	      if (redirect_url) {
	      	loading.close();
	        window.location.href = decodeURIComponent(redirect_url);
	        return;
	      }

	      await this.$store.dispatch('updateUserInfo');
	      loading.close();
	      this.$router.push({
	        path: '/',
	        query: {
	        	invite_code: inviteCode,
	        	reload: '1'
	        }
	      });
			}
			catch(err) {
				try {
					report({
			      event_type: 'click',
			      event_value: `apple_auth_fail: ${JSON.stringify(err)}`
			    });
				}
				catch(e) {}
				loading.close();
			}
		},

		async runAnima() {
			await sleep(1000);

			// 手指向三个点
			this.step1();
			await sleep(500);

			// 圆圈动画
			this.step2();
			await sleep(1000);

			// 弹出面板
			this.step3();
			await sleep(700);

			// 手指向open
			this.step4();
			await sleep(1000);

			// item动画
			this.step5();
			await sleep(1000);

			// 重置
			await this.step6();
			this.runAnima();
		}
	},

	mounted() {
		this.updateCopyLinkValue();

		if (this.showPhone) {
			this.runAnima();
			report({
	      event_type: 'view',
	      event_value: 'login_guide'
	    });
	    return;
		}

		if (this.showCopyLink) {
			report({
	      event_type: 'view',
	      event_value: 'login_copy'
	    });
	    return;
		}

		report({
      event_type: 'view',
      event_value: 'login_page'
    });
	}
}