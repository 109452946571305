const getters = {
	isLogin(state, getters) {
		return state.isLogin;
	},

	loginType(state, getters) {
		return state.loginType;
	},

	userName(state) {
		return state.userName;
	},

	userId(state) {
		return state.userId;
	},

	hasCheckIn(state) {
		return state.hasCheckIn;
	},

	photo(state) {
		return state.photo;
	},

	points(state) {
		return state.points;
	},

	inviteCode(state) {
		return state.inviteCode;
	},

	userToken(state) {
		return state.userToken;
	}
};

export default getters