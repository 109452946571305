import { network } from './network';

export async function getUserInfo(opts) {
	const res = await network({
		url: '/ai/api/web/v1/user/info',
		method: 'get',
		params: {},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function getCheckInStatus(opts) {
	const res = await network({
		url: '/ai/api/v1/checkIn/status',
		method: 'get',
		params: {},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}