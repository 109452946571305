import moment from 'moment';

export const timeMixin = {
	methods: {
		toLocalTime(time) {
			const utcTime = moment.utc(time);

      return utcTime.local().format('MM/YYYY HH:mm A');
		}
	}
};