import { loadingIcon } from '@/svg';

export default {
	name: 'AreaLoading',

	data() {
		return {
			loadingIcon
		};
	}
};