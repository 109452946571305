var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zelan-recharge scroll-view"},[_c('Navigation',{attrs:{"title":"Diamonds Shop"}}),_c('div',{staticClass:"zelan-recharge__body"},[_c('div',{staticClass:"zelan-recharge__balance-area"},[_c('div',{staticClass:"zelan-recharge__balance-butterfly"}),_c('h2',{staticClass:"zelan-recharge__balance-title"},[_vm._v("My diamonds balance")]),_c('div',{staticClass:"zelan-recharge__balance-value"},[_c('div',{staticClass:"zelan-recharge__balance-gemicon"}),_c('p',{staticClass:"zelan-recharge__balance-value-text"},[_vm._v(_vm._s(_vm.points))]),_c('div',{staticClass:"zelan-recharge__balance-recharge",on:{"click":_vm.detail}},[_vm._v("Detail")])])]),_c('div',{staticClass:"zelan-recharge__tab-wrap"},[_c('Tab',{attrs:{"dataList":_vm.tabList,"activeId":"apple"},on:{"onTabChange":_vm.onTabChange}})],1),_c('p',{staticClass:"zelan-recharge__tips"},[_vm._v("Choose a diamond recharge package")]),(_vm.pageLoading)?_c('div',{staticClass:"goods-loading"},[_c('AreaLoading')],1):_c('div',{staticClass:"zelan-recharge__list"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:item.id,staticClass:"zelan-recharge__list-item",style:({'margin-right': (index + 1) % 3 === 0 ? 0 : '30rem'}),on:{"click":function($event){return _vm.buy(item)}}},[_c('div',{staticClass:"zelan-recharge__list-item-top"},[_vm._m(0,true),_c('div',{staticClass:"zelan-recharge__list-item-diamondcount"},[_vm._v("+"+_vm._s(item.diamonds))])]),_c('p',{staticClass:"zelan-recharge__list-item-extra"},[_vm._v("Extra "+_vm._s(item.extra))]),_c('div',{staticClass:"zelan-recharge__list-item-buy"},[_vm._v("$"+_vm._s(item.price))])])}),0)]),_c('Dialog',{ref:"installAppTips",attrs:{"title":"Kind Tips","htmlContent":true,"footerButtons":[
      {
        id: 'download',
        name: `Download App`,
        color: '#e4e5e7',
        bgColor: '#5a64e9'
      },
      {
        id: 'notnow',
        name: 'Give up',
        color: '#e4e5e7',
        bgColor: '#383a42'
      },
    ]},on:{"onClickMask":_vm.giveUpInstall,"onClickButton":_vm.clickInstallAppTipsButton}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" Please complete the purchase in "),_c('span',[_vm._v("Zelan")]),_vm._v(" app. ")])]),_c('OpenApp',{attrs:{"bottom":50}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"padding-top":"20rem"}},[_c('div',{staticClass:"zelan-recharge__list-item-top-diamond"},[_c('span',{staticClass:"zelan-recharge__list-item-top-diamond-icon"})])])
}]

export { render, staticRenderFns }