export default {
	name: 'Dialog',

	props: {
		title: {
			type: String,
			default: 'Dialog'
		},

		content: {
			type: String,
			default: ''
		},

		htmlContent: {
			type: Boolean,
			default: false
		},

		footerButtons: {
			type: Array,
			default: () => []
		}
	},

	data() {
		return {
			visible: false
		}
	},

	methods: {
		hide() {
			this.visible = false;
		},

		show() {
			this.visible = true;
		},

		clickMask() {
			this.$emit('onClickMask');
		},

		clickButton(buttonInfo) {
			this.$emit('onClickButton', {
				...buttonInfo
			});
		}
	}
};