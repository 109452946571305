export const appMixin = {
  data() {
    return {
      appName: 'Zelan',
      email: "saintridge40@gmail.com",
      org: 'Mindmatrix Hongkong Limited',
      orgAdress: 'FLAT B01, 2/F, KIN TAK FUNGIND BUILDING, 174 WAI YIP STREET, KWUN TONG HONG KONG',
      discord: 'https://discord.gg/HUT5w7sNxV'
    }
  },
}