var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat"},[_c('Navigation',{attrs:{"title":_vm.roleName}}),(_vm.pageLoading)?_c('div',{staticClass:"chat__firstloading-wrap"},[_c('AreaLoading')],1):[(_vm.roleInfo.cover)?_c('div',{staticClass:"chat__bg",style:({'background-image': 'url('+ _vm.roleInfo.cover +')'})}):_vm._e(),_c('div',{staticClass:"chat__body"},[_c('div',{staticClass:"chat__body-topspace"}),_c('div',{staticClass:"chat__list"},_vm._l((_vm.messages),function(item){return _c('div',{key:item.id,class:{
          'chat__item-role-wrap': item.fromUser === 0,
          'chat__item-user-wrap': item.fromUser === 1
        }},[_c('div',{class:{
            'chat__item-role': item.fromUser === 0,
            'chat__item-user': item.fromUser === 1
          }},[_c('div',{class:{
              'chat__item-role-top': item.fromUser === 0,
              'chat__item-user-top': item.fromUser === 1
            }},[_c('img',{attrs:{"src":item.avatar,"alt":""}}),_c('h4',{staticClass:"ellipsis",class:{
                'chat__item-role-name': item.fromUser === 0,
                'chat__item-user-name': item.fromUser === 1
              }},[_vm._v(_vm._s(item.creatorName))])]),(item.type === 0)?_c('div',{staticClass:"chat__content",domProps:{"innerHTML":_vm._s(item.content)}}):_vm._e(),(item.type === 1)?_c('div',{staticStyle:{"height":"50rem","position":"relative"}},[_c('div',{staticClass:"loading-msg"},[_c('span',{staticClass:"loading-icon",domProps:{"innerHTML":_vm._s(_vm.loadingIcon)}})])]):_vm._e()])])}),0),_c('div',{staticClass:"chat__body-bottomspace"})]),_c('div',{staticClass:"chat__footer"},[_c('div',{staticClass:"chat__footer-input-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"chat__footer-input",attrs:{"type":"text","placeholder":"Send message"},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}})]),_c('div',{staticClass:"chat__footer-send",class:{'chat__footer-send--disable': _vm.sendButtonDisable},on:{"click":_vm.send}})])],_c('Dialog',{ref:"rechargeDialog",attrs:{"title":"Kind Tips","htmlContent":true,"footerButtons":[
      {
        id: 'recharge',
        name: `Recharge`,
        color: '#e4e5e7',
        bgColor: '#5a64e9'
      },
      {
        id: 'task',
        name: `Task`,
        color: '#e4e5e7',
        bgColor: '#5a64e9'
      },
      {
        id: 'notnow',
        name: 'Not now',
        color: '#e4e5e7',
        bgColor: '#383a42'
      },
    ]},on:{"onClickMask":_vm.cancelRecharge,"onClickButton":_vm.clickRechargeButton}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" Insufficient diamond balance. Please recharge or complete tasks to get more diamonds. ")])]),_c('Dialog',{ref:"installAppTips",attrs:{"title":"Kind Tips","content":"For a better conversation experience, download and use the app.","footerButtons":[
      {
        id: 'download',
        name: `Download App`,
        color: '#e4e5e7',
        bgColor: '#5a64e9'
      },
      {
        id: 'notnow',
        name: 'Not now',
        color: '#e4e5e7',
        bgColor: '#383a42'
      },
    ]},on:{"onClickMask":_vm.notNow,"onClickButton":_vm.clickInstallAppTipsButton}}),(_vm.isLogin)?_c('OpenApp',{attrs:{"bottom":170}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }