import { mapGetters } from 'vuex';
import Navigation from '@/components/Navigation/Navigation.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import Button from '@/components/Button/Button.vue';
import { getCheckInStatus } from '@/api/user';
import { report } from '@/api/report';
import { mixins } from '@/mixins';
import { getStorageData } from '@/utils/util';

export default {
	name: 'CheckIn',
	mixins: [mixins],
	components: {
		Navigation,
		OpenApp,
		Dialog,
		AreaLoading,
		Button
	},

	data() {
		return {
			dataList: [],
			pageLoading: false
		};
	},

	computed: {
		...mapGetters(['userToken']),
	},

	methods: {
		isValidate(info) {
			return !info.checked && info.validate;
		},

		isChecked(info) {
			return info.checked;
		},

		isReady(info) {
			return !info.checked && !info.validate;
		},

		async init() {
			this.pageLoading = true;
			await this.getStatusData();
			this.pageLoading = false;
		},

		async getStatusData() {
			const userToken = this.userToken || '';
			const res = await getCheckInStatus({
				token: userToken
			});

			this.dataList = this.makeRenderList(res.data);
		},

		makeRenderList(srcData) {
			const { currentCheckDays, hasCheckIn, rewards } = srcData;
			const result = [];

			rewards.forEach((item, index) => {
				result.push({
					id: `day${index + 1}`,
					checked: index < currentCheckDays || (index === currentCheckDays && hasCheckIn),
					gemCount: item.gems,
					validate: currentCheckDays === index && !hasCheckIn
				});
			});

			return result;
		},

		giveUpInstall() {
  		this.$refs.installAppTips.hide();
  		report({
	      event_type: 'click',
	      event_value: `click_checkin_giveupinstall`
	    });
  	},

  	clickInstallAppTipsButton(item) {
  		if (item.id === 'notnow') {
				this.giveUpInstall();
			}

			if (item.id === 'download') {
				this.goDownload();
				this.$refs.installAppTips.hide();
				report({
		      event_type: 'click',
		      event_value: `click_checkin_install`
		    });
			}
  	},

		checkin() {
			this.$refs.installAppTips.show();
			report({
	      event_type: 'click',
	      event_value: `click_checkin`
	    });
		}
	},

	mounted() {
		this.init();
		report({
      event_type: 'view',
      event_value: 'checkin_page'
    });
	}
};

