import { sleep, getStorageData } from '@/utils/util';
import { getUserInfo } from '@/api/user';
import { logout } from '@/api/login';

const resetState = {
	isLogin: false,
	userName: '',
	userId: '',
	type: 0,
	points: 0,
	photo: '',
	// inviteCode: '',
	userToken: '',
	loginType: null,
	hasCheckIn: true
};

const actions = {
	async updateUserInfo({ commit, state, rootState }, payload) {
		const userToken = await getStorageData('user_token');

		if (!userToken) {
			commit('UPDATE_USER_INFO', {
				...resetState
			});
			return;
		}

		try {
			const res = await getUserInfo({
				token: userToken
			});

			if (res.statusCode === 200) {
				commit('UPDATE_USER_INFO', {
					isLogin: true,
					userName: res.data.nickName,
					photo: res.data.avatar,
					points: res.data.points,
					userId: res.data.fakeId,
					loginType: res.data.registerChannel,
					hasCheckIn: res.data.hasCheckIn,
					userToken
				});
				return;
			}

			commit('UPDATE_USER_INFO', {
				...resetState
			});
		}
		catch(err) {
			commit('UPDATE_USER_INFO', {
				...resetState
			});
		}
	},

	async updateInviteCode({ commit, state, rootState }, payload) {
		commit('UPDATE_INVITE_CODE', {
			inviteCode: payload.inviteCode || ''
		});
	},

	async updateUserToken({ commit, state, rootState }, payload) {
		commit('UPDATE_USER_TOKEN', {
			userToken: payload.userToken || ''
		});
	},
}

export default actions;
