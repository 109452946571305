import { mapGetters } from 'vuex';
import { getStorageData } from '@/utils/util';

export const tabClickMixin = {
  computed: {
    ...mapGetters(['inviteCode']),
  },

	methods: {
		async onClickTab(tab) {
      const inviteCode = this.inviteCode || '';

      if (tab.id === 'you') {
        this.$router.push({
          path: `/user_center`,
          query: {
            invite_code: inviteCode,
          }
        });
      }

      if (tab.id === 'home') {
        this.$router.push({
          path: `/`,
          query: {
            invite_code: inviteCode,
          }
        });
      }

      if (tab.id === 'messages') {
        this.$router.push({
          path: `/sessions`,
          query: {
            invite_code: inviteCode,
          }
        });
      }
    },
	}
};