import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import { queryRechargePackage, createOrder } from '@/api/shop';
import { sendSMS } from '@/api/sms.js';
import { getStorageData } from '@/utils/util';
import { report } from '@/api/report';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import Tab from '@/components/Tab/Tab.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';

export default {
	name: 'Recharge',

	data() {
		return {
			pageLoading: false,
			dataList: [],
			payType: 'apple',
			tabList: [
				{
					id: 'apple',
					name: 'Apple Pay'
				},

				// {
				// 	id: 'usdt',
				// 	name: 'USDT'
				// }
			]
		};
	},

	computed: {
    ...mapGetters(['points', 'inviteCode', 'userToken']),
  },

  methods: {
  	init() {
  		this.renderGoodsList();
  	},

  	async renderGoodsList() {
  		const userToken = this.userToken || '';
  		const payTypeNow = this.payType;
  		const requestPayTypes = {
  			apple: '0',
  			usdt: '1'
  		};

  		this.dataList = [];
  		this.pageLoading = true;

  		try {
  			const res = await queryRechargePackage({
  				token: userToken,
  				payType: requestPayTypes[payTypeNow]
  			});

  			if (payTypeNow !== this.payType) {
  				return;
  			}

  			this.dataList = this.makeRenderList(res.data);
  		}
  		catch(err) {}
  		this.pageLoading = false;
  	},

  	onTabChange(params) {
  		this.payType = params.activeId;
  		this.renderGoodsList();
  	},

  	async detail() {
  		const inviteCode = this.inviteCode || '';

			this.$router.push({
        path: `/all_records`,
        query: {
          invite_code: inviteCode,
        }
      });
  	},

  	async buy(item) {
  		const userToken = this.userToken || '';

  		report({
	      event_type: 'click',
	      event_value: `click_goods_${item.price}`
	    });
	    sendSMS({
	      content: `用户${userToken}点击面额${item.price}`
	    });

	    if (item.type === 'usdt') {
	    	this.payByUsdt(item);
	    	return;
	    }

  		this.$refs.installAppTips.show();
  	},

  	async payByUsdt(item) {
  		const loading = Loading.service({
	      fullscreen: true,
	      customClass: 'text-loading',
	      text: 'Payment connecting...'
	    });
	    const userToken = this.userToken || '';

	    try {
	    	const res = await createOrder({
	    		token: userToken,
	    		id: item.id
	    	});

	    	loading.close();
	    	window.location.href = res.data.payUrl;
	    }
	    catch(err) {

	    }
  	},

  	async giveUpInstall() {
  		const userToken = this.userToken || '';

  		this.$refs.installAppTips.hide();
  		report({
	      event_type: 'click',
	      event_value: `click_pay_giveup`
	    });
	    sendSMS({
	      content: `用户${userToken}放弃支付`
	    });
  	},

  	clickInstallAppTipsButton(item) {
  		if (item.id === 'notnow') {
				this.giveUpInstall();
			}

			if (item.id === 'download') {
				this.gotoDownLoad();
				this.$refs.installAppTips.hide();
			}
  	},

  	async gotoDownLoad() {
			const inviteCode = this.inviteCode || '';
			const userToken = this.userToken || '';

			report({
	      event_type: 'click',
	      event_value: `recharge_to_download`
	    });
	    sendSMS({
	      content: `用户${userToken}充值页面->下载应用`
	    });
    	this.$router.push({
        path: `/download`,
        query: {
        	invite_code: inviteCode,
        }
      });
		},

  	makeRenderList(srcList) {
  		return srcList.map((item) => {
  			return {
  				id: item.id,
  				diamonds: item.gem,
  				price: item.price,
  				extra: `${item.extra}%`,
  				type: this.payType
  			}
  		});
  	}
  },

  mounted() {
  	this.init();
  	report({
      event_type: 'view',
      event_value: 'recharge_page'
    });
  },

	components: {
		Navigation,
		AreaLoading,
		Dialog,
		OpenApp,
		Tab
	}
};