import Navigation from '@/components/Navigation/Navigation.vue';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import DeviceDetector from "device-detector-js";
import { Loading } from 'element-ui';
import { mapGetters } from 'vuex';
import { loadingIcon } from '@/svg';
import { loginout } from '@/api/login';
import { queryRoleDetail, queryRoleCards } from '@/api/role';
import { report } from '@/api/report';
import { getStorageData } from '@/utils/util';

export default {
	components: {
		Navigation,
		AreaLoading,
		OpenApp
	},

	data() {
		return {
			pageLoading: false,
			cardsLoading: false,
			loadingIcon,
			showLoginPop: false,
			roleInfo: {},
			cardList: [],
			roleName: ''
		}
	},

	computed: {
    ...mapGetters(['userName', 'photo', 'isLogin', 'points', 'inviteCode']),
  },

	activated() {
		this.init();
	},

	created() {
    report({
      event_type: 'view',
      event_value: 'role_detail'
    });
  },

	methods: {
		back() {
			this.$router.back();
		},

    async goChat() {
    	const deviceDetector = new DeviceDetector();
      const deviceInfo = deviceDetector.parse(window.navigator.userAgent);
    	const inviteCode = this.inviteCode || '';

    	if (deviceInfo.os.name === 'Android' && this.isLogin) {
    		this.goDownload();
    		return;
    	}

    	this.$router.push({
        path: `/chat/${this.$route.params.roleId}`,
        query: {
        	invite_code: inviteCode,
        	nickname: encodeURIComponent(this.roleName)
        }
      });
      report({
	      event_type: 'click',
	      event_value: 'chat_now'
	    });
    },

    async goDownload() {
    	const inviteCode = this.inviteCode || '';

    	this.$router.push({
        path: `/download`,
        query: {
        	invite_code: inviteCode,
        }
      });
    },

		async init() {
			this.cardsLoading = false;
			this.pageLoading = true;
			this.setNickname();
			await this.getRoleInfo();
			this.pageLoading = false;
			await this.getCardList();
		},

		setNickname() {
			const nickname = this.$route.query.nickname || '';

			this.roleName = decodeURIComponent(nickname);
		},

		async getRoleInfo() {
			const res = await queryRoleDetail({
				roleId: this.$route.params.roleId
			});

			this.roleInfo = res.data;
		},

		async getCardList() {
			this.cardsLoading = true;
			const res = await queryRoleCards({
				roleId: this.$route.params.roleId
			});

			this.cardList = res.data;
			this.cardsLoading = false;
		}
	},
}