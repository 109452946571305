const mutations = {
	['UPDATE_USER_INFO'] (state, userInfo) {
		state.isLogin = userInfo.isLogin || false;
		state.userName = userInfo.userName || '';
		state.type = userInfo.type || 0;
		state.photo = userInfo.photo || '';
		state.points = userInfo.points || 0;
		state.userId = userInfo.userId || '';
		state.loginType = userInfo.loginType;
		state.hasCheckIn = userInfo.hasCheckIn;
		state.userToken = userInfo.userToken || '';
	},

	['UPDATE_SETTING'] (state, payload) {
		state.setting = payload;
	},

	['UPDATE_INVITE_CODE'] (state, payload) {
		state.inviteCode = payload.inviteCode;
	},

	['UPDATE_USER_TOKEN'] (state, payload) {
		state.userToken = payload.userToken;
	},

	['UPDATE_PLAN'] (state, payload) {
		state.vipLevel = payload.type;
		state.vipExpireDate = payload.vipExpireDate;
	},

	['UPDATE_LEMON_BEAN_COUNT'] (state, payload) {
		const { lemonBeanCount } = payload;
		
		state.lemonBeanCount = lemonBeanCount;
	}
};

export default mutations;