import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '@/pages/Home/Home.vue';
import UserCenter from '@/pages/UserCenter/UserCenter.vue';
import RoleDetail from '@/pages/RoleDetail/RoleDetail.vue';
import Login from '@/pages/Login/Login.vue';
import Download from '@/pages/Download/Download.vue';
import Setting from '@/pages/Setting/Setting.vue';
import Recharge from '@/pages/Recharge/Recharge.vue';
import AllRecords from '@/pages/AllRecords/AllRecords.vue';
import Sessions from '@/pages/Sessions/Sessions.vue';
import CheckIn from '@/pages/CheckIn/CheckIn.vue';
import Task from '@/pages/Task/Task.vue';
import Order from '@/pages/Order/Order.vue';
import NotFound from '@/pages/404/404.vue';
import Chat from '@/pages/Chat/Chat.vue';
import Contact from '@/pages/Contact/Contact.vue';
import About from '@/pages/About/About.vue';
import { queryUrl } from '@/utils/util';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/user_center',
    name: 'user_center',
    component: UserCenter,
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: Sessions,
  },
  {
    path: '/checkin',
    name: 'checkin',
    component: CheckIn,
  },
  {
    path: '/order',
    name: 'order',
    component: Order,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/setting',
    name: 'setting',
    component: Setting,
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: Recharge,
  },
  {
    path: '/task',
    name: 'task',
    component: Task,
  },
  {
    path: '/all_records',
    name: 'all_records',
    component: AllRecords,
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
  },
  {
    path: '/role_detail/:roleId',
    name: 'role_detail',
    component: RoleDetail,
  },
  {
    path: '/chat/:roleId',
    name: 'chat',
    component: Chat,
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  },
];

const router = new VueRouter({
  base: '/web',
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  const isLogin = store.getters.isLogin;
  const curHref = window.location.href;
  const { invite_code } = queryUrl(curHref);
  const inviteCode = invite_code || '';

  // 如果直接访问的是登录页，并且没有登录，则渲染登录页面
  if (/\/login/.test(to.fullPath) && !isLogin) {
    next();
    return;
  }

  // 如果直接访问的是登录页，已登录，则重定向到首页
  if (/\/login/.test(to.fullPath) && isLogin) {
    next(`/?invite_code=${inviteCode}`);
    return;
  }

  if (!isLogin
    && (/\/user_center/.test(to.fullPath)
      || /\/chat/.test(to.fullPath)
      || /\/task/.test(to.fullPath)
      || /\/setting/.test(to.fullPath)
      || /\/checkin/.test(to.fullPath)
      || /\/order/.test(to.fullPath)
      || /\/sessions/.test(to.fullPath)
      || /\/recharge/.test(to.fullPath)
      || /\/all_records/.test(to.fullPath)
      || /\/download/.test(to.fullPath))) {
    next(`/login?invite_code=${inviteCode}`);
    return;
  }

  next();
})

export default router;


