import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import { loginout } from '@/api/login';
import { report } from '@/api/report';
import { sendSMS } from '@/api/sms.js';
import { download } from '@/api/ad.js';
import { getStorageData } from '@/utils/util';
import Dialog from '@/components/Dialog/Dialog.vue';

export default {
	data() {
		return {};
	},

	computed: {
    ...mapGetters(['userName', 'photo', 'isLogin', 'points', 'loginType', 'userId']),

    loginTypeFormat() {
      const names = {
        0: 'Apple',
        1: 'Google',
        2: 'Email'
      };

      return names[this.loginType];
    }
  },

  created() {
    report({
      event_type: 'view',
      event_value: 'download_page',
      user_id: this.userId
    });
  },

	methods: {
		back() {
      this.$router.back();
		},

    downloadApp() {
      this.$refs.installAppTips.show();
    },

    giveUpInstall() {
      this.$refs.installAppTips.hide();
    },

    clickInstallAppTipsButton(item) {
      if (item.id === 'ok') {
        this.$refs.installAppTips.hide();
        this.openAppstore();
      }
    },

    openAppstore() {
      try {
        download();
        report({
          event_type: 'click',
          event_value: 'download',
          user_id: this.userId
        });
        sendSMS({
          content: `用户${this.userId}下载应用`
        });
      }catch(err) {
        console.log(err);
      }

      setTimeout(() => {
        window.location.href = 'https://apps.apple.com/us/app/zelan/id6738687775';
      }, 10);
    },
	},

  components: {
    Dialog
  }
}