import { network } from './network';

export async function getChatInfo(opts) {
	const res = await network({
		url: '/ai/api/web/v1/conversion/room/info',
		method: 'post',
		params: {
			roleId: opts.roleId
		},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function sendMessage(opts) {
	const res = await network({
		url: '/ai/api/v1/conversion/new',
		method: 'post',
		params: {
			roomId: opts.roomId,
			lastMessageId: opts.lastMessageId,
			question: opts.question
		},
		headers: {
			token: opts.token,
			'zelan-device': '1'
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function getSessionInfo(opts) {
	const res = await network({
		url: '/ai/api/v1/conversion/room/info',
		method: 'post',
		params: {
			sid: opts.sid,
			roleId: opts.roleId,
		},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function getSessionList(opts) {
	const res = await network({
		url: '/ai/api/v1/conversion/room/all',
		method: 'get',
		params: {},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}
