import { mapGetters } from 'vuex';
import TabBar from '@/components/TabBar/TabBar.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import NoMoreData from '@/components/NoMoreData/NoMoreData.vue';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import DataPlaceHolder from '@/components/DataPlaceHolder/DataPlaceHolder.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import { mixins } from '@/mixins';
import { report } from '@/api/report';
import { getSessionList } from '@/api/chat';
import { getStorageData } from '@/utils/util';

export default {
	components: {
		TabBar,
		Navigation,
		NoMoreData,
		AreaLoading,
		DataPlaceHolder,
		OpenApp
	},
	name: 'Sessions',
	mixins: [mixins],

	data() {
		return {
			firstLoading: false,
			dataList: [],
			firstLoading: false,
		};
	},

	computed: {
    ...mapGetters(['inviteCode', 'userToken']),
  },

	methods: {
		async init() {
			this.firstLoading = true;
			this.dataList = [];
			await this.getMessageList();
			this.firstLoading = false;
		},

		async getMessageList() {
			const userToken = this.userToken || '';
			const res = await getSessionList({
				token: userToken
			});
			const currentList = this.makeRenderList(res.data);

			this.dataList = currentList;
		},

		makeRenderList(dataList) {
			return dataList.map((item) => {
				return {
					id: item.id,
					content: item.content,
					avatar: item.metaInfo.avatar,
					roleNickname: item.metaInfo.roleNickname,
					time: this.toLocalTime(item.updateTime),
					roleId: item.roleId
				};
			});
		},

		async clickSession(item) {
			const inviteCode = this.inviteCode || '';

    	this.$router.push({
        path: `/chat/${item.roleId}`,
        query: {
        	invite_code: inviteCode,
        	sid: item.id,
        	nickname: item.roleNickname
        }
      });
	    report({
        event_type: 'click',
        event_value: 'click_session'
      });
		},
	},

	activated() {
  	this.init();
  	report({
      event_type: 'view',
      event_value: 'messages'
    });
  },
};