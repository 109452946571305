export default {
	name: 'DataPlaceHolder',

	props: {
		type: {
			type: String,
			default: 'empy'	
		},

		content: {
			type: String,
			default: 'No data yet.'
		}
	}
};