var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zelan-setting scroll-view"},[_c('Navigation',{attrs:{"title":"Setting"}}),_c('div',{staticClass:"zelan-usercenter__body"},[_c('OperateList',{staticStyle:{"margin-bottom":"70rem"},attrs:{"dataList":_vm.operateDataList1},on:{"onClickOperate":_vm.onClickOperate}}),_c('OperateList',{attrs:{"dataList":_vm.operateDataList2},on:{"onClickOperate":_vm.onClickOperate}})],1),_c('Dialog',{ref:"delAccountdialog",attrs:{"title":"Delete Account","content":"Are you sure delete your account?","footerButtons":[
      {
        id: 'delete_btn',
        name: 'Delete',
        color: '#e4e5e7',
        bgColor: '#ce5951'
      },
      {
        id: 'cancel_btn',
        name: 'Cancel',
        color: '#e4e5e7',
        bgColor: '#383a42'
      }
    ]},on:{"onClickMask":_vm.cancelDel,"onClickButton":_vm.clickDialogBtn}}),_c('OpenApp',{attrs:{"bottom":100}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }