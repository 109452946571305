import { network } from './network';

export function sendSMS(opts) {
	if (process.env.NODE_ENV === 'development') {
		return;
	}
	
	network({
		url: 'https://chat.next-ai.top/api/sms_aiflow',
		method: 'post',
		params: {
			secret_key: 'sk-zOnDiQdfdcGer9yj',
      message: opts.content
		}
	});
}