import { mixins } from '@/mixins';

export default {
  name: 'About',

  mixins: [ mixins ],

  data() {
    return {}
  },
}
