import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	isLogin: false,
	userName: '',
	type: 0,
	photo: '',
	userId: '',
	points: 0,
	hasCheckIn: true,
	inviteCode: '',
	userToken: '',

	// 0：apple  1：google  2：email
	loginType: null
}

export default {
	state,
	getters,
	actions,
	mutations
};