import { mapGetters } from 'vuex';
import { report } from '@/api/report';
import { sendSMS } from '@/api/sms.js';
import { mixins } from '@/mixins';
import { getStorageData } from '@/utils/util';
import TabBar from '@/components/TabBar/TabBar.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import OperateList from '@/components/OperateList/OperateList.vue';

export default {
	name: 'UserCenter',
	mixins: [mixins],

	data() {
		return {
			operateDataList: [
				// {
				// 	id: 'feedback',
				// 	name: 'Feedback',
				// 	icon: 'icon-feedback',
				// 	color: '#e4e5e7',
				// 	leaf: false
				// },
				// {
				// 	id: 'checkin',
				// 	name: 'Checkin',
				// 	icon: 'icon-checkin',
				// 	color: '#e4e5e7',
				// 	leaf: false,
				// 	redPoint: !this.hasCheckIn
				// },
				{
					id: 'task',
					name: 'Task',
					icon: 'icon-task',
					color: '#e4e5e7',
					sub: 'Get more diamonds',
					leaf: false,
					redPoint: false
				},
				// {
				// 	id: 'order',
				// 	name: 'Order',
				// 	icon: 'icon-order',
				// 	color: '#e4e5e7',
				// 	leaf: false,
				// 	redPoint: false
				// },
				{
					id: 'contact',
					name: 'Contact',
					icon: 'icon-discord',
					color: '#e4e5e7',
					leaf: false,
					redPoint: false
				},

				{
					id: 'setting',
					name: 'Setting',
					icon: 'icon-setting',
					color: '#e4e5e7',
					leaf: false,
					redPoint: false
				}
			]
		};
	},

	computed: {
    ...mapGetters(['userName', 'photo', 'isLogin', 'points', 'userId', 'hasCheckIn', 'inviteCode', 'userToken']),
  },

	methods: {
		async onClickOperate(item) {
			const inviteCode = this.inviteCode || '';

			if (item.id === 'setting') {
				this.$router.push({
          path: `/setting`,
          query: {
            invite_code: inviteCode,
          }
        });
			}

			if (item.id === 'checkin') {
				this.$router.push({
          path: `/checkin`,
          query: {
            invite_code: inviteCode,
          }
        });
			}

			if (item.id === 'order') {
				this.$router.push({
          path: `/order`,
          query: {
            invite_code: inviteCode,
          }
        });
			}

			if (item.id === 'task') {
				this.$router.push({
          path: `/task`,
          query: {
            invite_code: inviteCode,
          }
        });
			}

			if (item.id === 'contact') {
				this.openContact();
			}
		},

		async openContact() {
			const userToken = this.userToken || '';

			report({
        event_type: 'click',
        event_value: 'contact'
      });
      sendSMS({
	      content: `用户${userToken}点击discord`
	    });
      window.location.href = 'https://discord.gg/HUT5w7sNxV';
    },

		async recharge() {
			const inviteCode = await getStorageData('invite_code');

			this.$router.push({
        path: `/recharge`,
        query: {
          invite_code: inviteCode,
        }
      });
		},

		copy() {
			const textarea = document.createElement('textarea');

			textarea.value = this.userId;      
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      
      this.$message({
        message: 'Copy success',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
		},
	},

	activated() {
		report({
      event_type: 'view',
      event_value: 'user_center'
    });
	},

	components: {
		TabBar,
		OperateList,
		OpenApp
	}
};