import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import DeviceDetector from "device-detector-js";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import RoleCard from '@/components/RoleCard/RoleCard.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import NoMoreData from '@/components/NoMoreData/NoMoreData.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import TabBar from '@/components/TabBar/TabBar.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import { loadingIcon } from '@/svg';
import { loginout } from '@/api/login';
import { queryTopRoles, queryTabRoles } from '@/api/role';
import { report } from '@/api/report';
import { sendSMS } from '@/api/sms.js';
import { mixins } from '@/mixins';
import { getStorageData } from '@/utils/util';

export default {
  components: {
     Swiper,
     SwiperSlide,
     RoleCard,
     Navigation,
     AreaLoading,
     NoMoreData,
     Dialog,
     TabBar,
     OpenApp
  },
  
  name: 'Home',

  mixins: [mixins],

  data() {
    return {
      loadingIcon,
      pageLoading: false,
      unfiltered: false,
      showDialog: false,
      topList: [],
      tabList: [],
      roleList: [],
      activeTabId: '',
      page: 0,
      roleListLoading: false,
      nomoreData: false,
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 10,
        initialSlide: 1,
        centeredSlides: true,
        loop: true,
        effect : 'coverflow',
        coverflowEffect: {
          rotate: 30,
          stretch: 10,
          depth: 200,
          modifier: 2,
          slideShadows : true
        }
      }
    }
  },

  computed: {
    ...mapGetters(['userName', 'photo', 'isLogin', 'points', 'hasCheckIn', 'inviteCode']),
  },

  created() {
    const unfiltered = localStorage.getItem('unfiltered') || '';

    this.unfiltered = unfiltered === '1';
    report({
      event_type: 'view',
      event_value: 'home_page'
    });
  },

  // activated() {
  //   console.log('activated');
  //   const unfiltered = localStorage.getItem('unfiltered') || '';
  //   const { reload } = this.$route.query;

  //   this.unfiltered = unfiltered === '1';

  //   if (reload === '1') {
  //     this.resetSataus();
  //     this.queryInitialData();
  //   }
  // },

  mounted() {
    this.init();

    try {
      const deviceDetector = new DeviceDetector();
      const deviceInfo = deviceDetector.parse(window.navigator.userAgent);
      
      report({
        event_type: 'view',
        event_value: `os_${deviceInfo.os.name}_${deviceInfo.os.version}`
      });
    }
    catch(err) {}
  },

  methods:{
    async init() {
      this.queryInitialData();

      if (/zelantk/.test(window.location.href)) {
        return;
      }

      if (!this.unfiltered) {
        this.unfilteredChange(true);
      }
    },

    unfilteredChange(value) {
      report({
        event_type: 'click',
        event_value: 'filter_switch'
      });

      if (value) {
        this.showDialog = true;
        this.$refs.over18dialog.show();
        return;
      }

      this.unfiltered = false;
      this.updateUnfiltered();
      this.resetSataus();
      this.queryInitialData();
    },

    over18() {
      report({
        event_type: 'click',
        event_value: 'over18'
      });
      this.$refs.over18dialog.hide();
      this.unfiltered = true;
      this.showDialog = false;
      this.updateUnfiltered();
      this.resetSataus();
      this.queryInitialData();
    },

    cancel18() {
      report({
        event_type: 'click',
        event_value: 'not18'
      });
      this.$refs.over18dialog.hide();
      this.unfiltered = false;
      this.showDialog = false;
      this.updateUnfiltered();
    },

    filterComfirm(button) {
      if (button.id === 'cancel18') {
        this.cancel18();
      }

      if (button.id === 'over18') {
        this.over18();
      }
    },

    updateUnfiltered() {
      localStorage.setItem('unfiltered', this.unfiltered ? '1' : '0');
    },

    resetSataus() {
      this.pageLoading = false;
      this.topList = [];
      this.tabList = [];
      this.roleList = [];
      this.activeTabId = '';
      this.page = 0;
      this.roleListLoading = false;
      this.nomoreData = false;
    },

    async queryInitialData() {
      this.pageLoading = true;
      const res = await queryTopRoles({
        c: this.unfiltered ? 1 : 0
      });

      this.topList = this.makeTopRenderList(res.data.recommends);
      this.pageLoading = false;
      this.tabList = this.makeTabRenderList(res.data.categories);
      this.activeTabId = this.tabList[0].id;
      this.roleListLoading = true;
      await this.getRoleList({
        cid: this.activeTabId,
        page: this.page,
        c: this.unfiltered ? 1 : 0
      });
      this.roleListLoading = false;
    },

    async onClickCard(opts) {
      const inviteCode = this.inviteCode || '';

      this.$router.push({
        path: `/role_detail/${opts.id}`,
        query: {
          invite_code: inviteCode,
          nickname: encodeURIComponent(opts.nickName)
        }
      });
      report({
        event_type: 'click',
        event_value: 'role_card'
      });
    },

    async goCheckIn() {
      const inviteCode = this.inviteCode || '';

      this.$router.push({
        path: `/checkin`,
        query: {
          invite_code: inviteCode,
        }
      });
      report({
        event_type: 'click',
        event_value: 'home_checkin'
      });
    },

    async switchTab(cid) {
      report({
        event_type: 'click',
        event_value: 'tab_switch'
      });

      this.page = 0;
      this.nomoreData = false;
      this.activeTabId = cid;
      this.roleListLoading = true;
      this.roleList = [];
      await this.getRoleList({
        cid: this.activeTabId,
        page: this.page,
        c: this.unfiltered ? 1 : 0
      });
      this.roleListLoading = false;
    },

    async getRoleList(opts) {
      const { cid, page } = opts;
      const res = await queryTabRoles({
        cid,
        page,
        c: this.unfiltered ? 1 : 0
      });
      const list = this.makeRoleRenderList(res.data);

      if (!list.length) {
        this.nomoreData = true;
        return;
      }
      else {
        this.nomoreData = false;
      }

      this.roleList = this.roleList.concat(list);
    },

    async onScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      
      if (this.roleListLoading || this.nomoreData || this.pageLoading) {
        return;
      }

      if(scrollTop + clientHeight >= scrollHeight - 200) {
        this.page++;
        this.roleListLoading = true;
        report({
          event_type: 'view',
          event_value: 'role_scroll'
        });
        await this.getRoleList({
          cid: this.activeTabId,
          page: this.page,
          c: this.unfiltered ? 1 : 0
        });
        this.roleListLoading = false;
      }
    },

    makeRoleRenderList(srcList) {
      const result = [];

      srcList.forEach((item) => {
        result.push({
          avatar: item.avatar,
          cover: item.cover,
          description: item.description,
          id: item.id,
          nickName: item.nickName,
          tags: item.tags,
          favCount: item.favCount
        });
      });

      return result;
    },

    makeTopRenderList(srcList) {
      const result = [];

      srcList.forEach((item) => {
        result.push({
          cover: item.cover,
          id: item.id,
          nickName: item.nickName,
          tags: item.tags
        });
      });

      return result;
    },

    makeTabRenderList(srcList) {
      const result = [];

      srcList.forEach((item) => {
        result.push({
          name: item.name,
          id: item.id,
        });
      });

      return result;
    },

    onSlideChange(){
      report({
        event_type: 'view',
        event_value: 'top_card'
      });
    }
  }
}
