import Vue from 'vue';
import * as fundebug from "fundebug-javascript";
import FundebugVue from "fundebug-vue";
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI, { Loading } from 'element-ui';
import { sleep, queryUrl, getBaseInviteCode } from '@/utils/util';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';

fundebug.init({
  apikey: '1b7bafcc364f3f4cded45bd2f2814d4440a0821269c475e596d8c089140d05d4'
})
new FundebugVue(fundebug).installVueErrorHandler(Vue);

const unfiltered = localStorage.getItem('unfiltered') || '';

if (!unfiltered) {
  localStorage.setItem('unfiltered', '0');
}

Vue.use(ElementUI, {locale});
Vue.config.productionTip = false;

async function getUserInfo() {
  const loading = Loading.service({
    fullscreen: true,
    customClass: 'app-loading',
    background: '#1c1d21'
  });
  const { invite_code } = queryUrl(window.location.href);
  const defaultInviteCode = getBaseInviteCode();

  if (invite_code) {
    localStorage.setItem('invite_code', invite_code);
  }
  else {
    localStorage.setItem('invite_code', defaultInviteCode);
  }
  
  await sleep(10);
  await store.dispatch('updateUserInfo');
  store.dispatch('updateInviteCode', {
    inviteCode: invite_code || defaultInviteCode
  });
  loading.close();
}

Promise.all([
  getUserInfo()
]).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});

try {
  window.ZELAN_ENV = {
    ...process.env
  };
  console.log(process.env);
}
catch(err) {}
