import { network } from './network';

export async function queryRechargePackage(opts) {
	const res = await network({
		url: '/ai/api/v1/charge/packages',
		method: 'get',
		params: {
			payType: opts.payType
		},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function queryBillList(opts) {
	const res = await network({
		url: '/ai/api/v1/consume/record',
		method: 'get',
		params: {
			page: opts.page
		},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

export async function createOrder(opts) {
	const res = await network({
		url: '/ai/api/v1/charge/order/create/usdt',
		method: 'post',
		params: {
			id: opts.id
		},
		headers: {
			token: opts.token
		}
	});

	if (res.statusCode !== 200) {
		return Promise.reject(res);
	}

	return res;
}

