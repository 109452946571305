var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"download"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"nav__back",on:{"click":_vm.back}}),_c('h2',{staticClass:"nav__title ellipsis pos-center"},[_vm._v("Download App")])]),_c('div',{staticClass:"download__body"},[_vm._m(0),_c('p',{staticClass:"download__tips"},[_vm._v("You are currently logged in with "),_c('span',[_vm._v(_vm._s(_vm.loginTypeFormat))]),_vm._v(". Please download the app and log in with the "),_c('span',[_vm._v("Same Account")]),_vm._v(" to continue using more features.")]),_c('div',{staticClass:"download__methods"},[_c('div',{staticClass:"download__method",on:{"click":_vm.downloadApp}},[_vm._m(1)])])]),_c('Dialog',{ref:"installAppTips",attrs:{"title":"Important","htmlContent":true,"footerButtons":[
      {
        id: 'ok',
        name: `OK`,
        color: '#e4e5e7',
        bgColor: '#5a64e9'
      }
    ]},on:{"onClickMask":_vm.giveUpInstall,"onClickButton":_vm.clickInstallAppTipsButton}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" You are currently using a "),_c('span',[_vm._v(_vm._s(_vm.loginTypeFormat))]),_vm._v(" account. Please Log in to Zelan app using the "),_c('span',[_vm._v("Same Account")]),_vm._v(". ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"download__appicon"},[_c('span',{staticClass:"download__appicon-inner"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"download__method-inner"},[_c('div',{staticClass:"download__icon download__apple-icon"}),_c('p',[_vm._v("Download App")])])
}]

export { render, staticRenderFns }