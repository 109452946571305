export default {
	name: 'Tab',

	props: {
		dataList: {
			type: Array,
			default: () => []
		},

		activeId: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			activeIdPrivate: this.activeId,
			sliderX: 0
		};
	},

	methods: {
		onTabSwitch(item, index) {
			this.activeIdPrivate = item.id;
			this.sliderX = 100 * index;
			this.$emit('onTabChange', {
				activeId: this.activeIdPrivate
			});
		}
	}
};