import hljs from 'highlight.js';
import { mapGetters } from 'vuex';
import { Loading } from 'element-ui';
import { marked }  from 'marked';
import { queryRoleDetail } from '@/api/role';
import { loadingIcon } from '@/svg';
import { loginout } from '@/api/login';
import { getSessionInfo, sendMessage } from '@/api/chat';
import { uuid, sleep } from '@/utils/util';
import { report } from '@/api/report';
import Dialog from '@/components/Dialog/Dialog.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import 'highlight.js/styles/atom-one-dark.css';
import { sendSMS } from '@/api/sms.js';

export default {
	components: {
		Dialog,
		Navigation,
		AreaLoading,
		OpenApp
	},

	data() {
		return {
			roleInfo: {},
			loadingIcon,
			pageLoading: false,
			showLoginPop: false,
			messages: [],
			inputValue: '',
			sendding: false,
			chatInfo: {},
			roleName: ''
		}
	},

	computed: {
    ...mapGetters(['userName', 'photo', 'isLogin', 'points', 'inviteCode', 'userToken']),

    sendButtonDisable() {
    	const formatValue = this.inputValue.trim();

    	if (this.sendding || formatValue === '') {
    		return true;
    	}

    	if (/^\s+$/.test(formatValue)) {
    		return true;
    	}

    	return false;
    }
  },

	activated() {
		this.init();
		report({
      event_type: 'view',
      event_value: 'chat_page'
    });
	},

	methods: {
		async init() {
			this.setNickname();
			this.initMarkdownRender();
			this.pageLoading = true;
			await Promise.all([
				this.getRoleInfo(),
				this.initChat()
			]);
			this.pageLoading = false;
			this.scrollToEnd();
		},

		clickInstallAppTipsButton(item) {
			if (item.id === 'notnow') {
				this.notNow();
			}

			if (item.id === 'download') {
				this.gotoDownLoad();
			}
		},

		notNow() {
			this.$refs.installAppTips.hide();
			report({
        event_type: 'click',
        event_value: 'chat_cancel_download'
      });
		},

		async gotoDownLoad() {
			const inviteCode = this.inviteCode || '';

    	this.$router.push({
        path: `/download`,
        query: {
        	invite_code: inviteCode,
        }
      });
	    report({
        event_type: 'click',
        event_value: 'chat_to_download'
      });
		},

		clickRechargeButton(item) {
			if (item.id === 'notnow') {
				this.cancelRecharge();
			}

			if (item.id === 'recharge') {
				this.recharge();
				this.$refs.rechargeDialog.hide();
			}

			if (item.id === 'task') {
				this.goTask();
				this.$refs.rechargeDialog.hide();
			}
		},

		cancelRecharge() {
			this.$refs.rechargeDialog.hide();
	    report({
        event_type: 'click',
        event_value: 'chat_page_recharge_cancel'
      });
		},

		async goTask() {
    	const inviteCode = this.inviteCode || '';

    	this.$router.push({
        path: `/task`,
        query: {
        	invite_code: inviteCode,
        }
      });
	    report({
        event_type: 'click',
        event_value: 'chat_to_task'
      });
    },

		async recharge() {
    	const inviteCode = this.inviteCode || '';

    	this.$router.push({
        path: `/recharge`,
        query: {
        	invite_code: inviteCode,
        }
      });
	    report({
        event_type: 'click',
        event_value: 'chat_to_recharge'
      });
    },

		async initChat() {
			const userToken = this.userToken || '';
			const params = {
				roleId: this.$route.params.roleId,
				token: userToken,
			};

			if (this.$route.query.sid) {
				params.sid = this.$route.query.sid;
			}

			const res = await getSessionInfo(params);

			this.chatInfo = res.data;
			this.messages = this.makeMessagesRenderList(res.data.messages);
		},

		makeMessagesRenderList(srcList) {
			const result = [];

			srcList.forEach((item) => {
				let content = item.contents[0] || '';

				content = content.replace(/^{/, '');
				content = content.replace(/}$/, '');
				result.push({
					avatar: item.avatar,
					creatorName: item.creatorName,
					id: item.id,
					content: marked(content),
					fromUser: item.fromUser,
					type: 0
				});
			});

			return result;
		},

    async send() {
    	const formatValue = this.inputValue.trim();

    	if (this.sendding || !formatValue) {
    		return;
    	}

    	report({
        event_type: 'click',
        event_value: 'send_message'
      });

    	const userToken = this.userToken || '';
    	const sendMsgValue = formatValue;
    	const lastMessageId = this.messages[this.messages.length - 1].id;
    	const fakeUserId = uuid();
    	const fakeUserMsg = {
    		avatar: this.photo,
				creatorName: this.userName,
				id: fakeUserId,
				content: marked(sendMsgValue),
				fromUser: 1,
				type: 0
    	};

    	this.messages.push(fakeUserMsg);

    	const fakeRoleId = uuid();
    	const fakeRoleMsg = {
    		avatar: this.roleInfo.avatar,
				creatorName: this.roleInfo.nickName,
				id: fakeRoleId,
				content: '',
				fromUser: 0,
				type: 1
    	};

    	this.messages.push(fakeRoleMsg);
    	this.sendding = true;
    	this.inputValue = '';
    	this.scrollToEnd();

    	try {
    		const res = await sendMessage({
	    		token: userToken,
	    		roomId: this.chatInfo.id,
	    		lastMessageId,
	    		question: sendMsgValue
	    	});

	    	let content = res.data.message.contents[0] || '';

				content = content.replace(/^{/, '');
				content = content.replace(/}$/, '');
	    	fakeUserMsg.id = res.data.message.parentId;
	    	fakeRoleMsg.id = res.data.message.id;
	    	fakeRoleMsg.content = marked(content);
	    	fakeRoleMsg.type = 0;
	    	this.sendding = false;
	    	this.$store.dispatch('updateUserInfo');
    	}
    	catch(err) {
    		this.messages.pop();
    		this.messages.pop();
    		this.sendding = false;
    		this.inputValue = sendMsgValue;
    		this.scrollToEnd();
    		
    		if (err.statusCode === 6004) {
			    this.$refs.rechargeDialog.show();
			    sendSMS({
			      content: `用户${userToken}展示余额不足对话框`
			    });
			    report({
		        event_type: 'view',
		        event_value: 'no banlance dialog'
		      });
			    return;
    		}

    		if (err.statusCode === 6026) {
    			this.$message({
			      message: 'Login has expired',
			      type: 'error',
			      showClose: true,
			      customClass: 'custom-message',
			    });
			    localStorage.setItem('user_token', '');
		      this.$store.dispatch('updateUserToken', {
		      	userToken: ''
		      });
		      this.$store.dispatch('updateUserInfo');
		      this.$router.push({
		        path: '/login',
		        query: {
		        	invite_code: this.inviteCode,
		        }
		      });
			    return;
    		}

    		this.$message({
		      message: 'Error, please try again',
		      type: 'error',
		      showClose: true,
		      customClass: 'custom-message',
		    });

		    try {
		    	sendSMS({
			      content: `用户${userToken}发送消息失败: ${JSON.stringify(err)}`
			    });
			    report({
		        event_type: 'view',
		        event_value: `发送消息失败:${JSON.stringify(err)}`
		      });
		    }
		    catch(err) {}
    	}
    },

    scrollToEnd() {
    	setTimeout(() => {
    		const scrollView = this.$el.querySelector('.chat__body');
    		
    		scrollView.scrollTo(0, scrollView.scrollHeight);
    	}, 0);
    },

		back() {
			this.$router.back();
		},

		setNickname() {
			const nickname = this.$route.query.nickname || '';

			this.roleName = decodeURIComponent(nickname);
		},

		async getRoleInfo() {
			const res = await queryRoleDetail({
				roleId: this.$route.params.roleId
			});

			this.roleInfo = res.data;
		},

		initMarkdownRender() {
      const renderer = new marked.Renderer();

      marked.setOptions({
        renderer: renderer,
        langPrefix: 'hljs language-',
        pedantic: false,
        gfm: true,
        breaks: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: false,
      });
    },
	},
};