import Button from '@/components/Button/Button.vue';

export default {
	name: 'TaskItem',

	components: {
		Button
	},

	props: {
		dataSrc: {
			type: Object,
			default: () => {}
		},
	},

	data() {
		return {}
	},

	methods: {
		clickBtn() {
			this.$emit('onClickTask', {
				...this.dataSrc
			});
		}
	}
};