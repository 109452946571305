export default {
	props: {
		dataSrc: {
			type: Object,
			default: () => {}
		},
	},

	data() {
		return {}
	},

	methods: {
		clickCard() {
			this.$emit('onClickCard', {
				id: this.dataSrc.id,
				nickName: this.dataSrc.nickName,
				description: this.dataSrc.description,
				cover: this.dataSrc.cover,
				tags: this.dataSrc.tags
			});
		}
	}
}