import { mapGetters } from 'vuex';
import Navigation from '@/components/Navigation/Navigation.vue';
import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import DataPlaceHolder from '@/components/DataPlaceHolder/DataPlaceHolder.vue';
import NoMoreData from '@/components/NoMoreData/NoMoreData.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import { queryBillList } from '@/api/shop';
import { mixins } from '@/mixins';
import { sendSMS } from '@/api/sms.js';
import { report } from '@/api/report';
import { getStorageData } from '@/utils/util';

export default {
	name: 'AllRecords',

	mixins: [mixins],

	data() {
		return {
			firstLoading: false,
			dataList: [],
			nomoreData: false,
			firstLoading: false,
			listLoading: false,
			page: 0
		};
	},

	computed: {
    ...mapGetters(['userToken']),
  },

	methods: {
		async init() {
			this.firstLoading = true;
			this.dataList = [];
			this.nomoreData = false;
			this.listLoading = false;
			this.page = 0;
			await this.getBillList({
				page: this.page
			});
			this.firstLoading = false;
		},

		async getBillList(opts) {
			const { page } = opts;
			const userToken = this.userToken || '';
			const res = await queryBillList({
				token: userToken,
				page
			});
			const currentList = this.makeRenderList(res.data);

			if (!currentList.length) {
				this.nomoreData = true;
				return;
			}

			this.dataList = this.dataList.concat(currentList);
		},

		async onScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      
      if (this.listLoading || this.nomoreData || this.firstLoading) {
        return;
      }

      if(scrollTop + clientHeight >= scrollHeight - 200) {
        this.page++;
        this.listLoading = true;
        await this.getBillList({
          page: this.page,
        });
        this.listLoading = false;
      }
    },

		makeRenderList(dataList) {
			return dataList.map((item) => {
				return {
					id: item.id,
					consumePoint: item.consumePoint,
					taskName: item.taskName,
					time: this.toLocalTime(item.consumeTime)
				};
			});
		}
	},

	activated() {
  	this.init();
  	report({
      event_type: 'view',
      event_value: 'allrecords_page'
    });
  },

	components: {
		Navigation,
		AreaLoading,
		NoMoreData,
		DataPlaceHolder,
		OpenApp
	}
};