export function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function queryUrl(url) {
  let paramStr = url.split('?')[1];
  let result = {};

  if (!paramStr) {
      return result;
  }

  let paramList = paramStr.split('&');

  paramList.forEach((param) => {
      let key = param.split('=')[0];
      let value = param.split('=')[1];

      result[key] = value;
  });

  return result;
}

export function getBaseInviteCode() {
  const baseInviteCodeMaps = {
    'zelan.the-path.top': '6C1CTP',
    'zelantk.the-path.top': 'JVZ8WB',
    'web.zelanai.com': 'NHYQDM',
    'm.x-matrix.top': 'PDRL9P'
  };

  return baseInviteCodeMaps[document.domain] || '';
}

function inFB() {
  const ua = window.navigator.userAgent;

  return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
}

export function uuid(len = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < len; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

function inMeseenger() {
  return inFB();
}

function inInstagram() {
  const ua = window.navigator.userAgent;
  
  return (ua.indexOf('Instagram') > -1);
}

export function isInAppWebview() {
  return inInstagram() || inMeseenger() || inFB() || isTT();
}

export function isTTSystem() {
  const ua = window.navigator.userAgent;
  
  return (ua.indexOf('ByteLo') > -1);
}

export function isFBSystem() {
  return inInstagram() || inMeseenger() || inFB();
}

export async function getStorageData(key) {
  const value = localStorage.getItem(key) || '';
  await sleep(10);

  return value;
}


