import { render, staticRenderFns } from "./AreaLoading.vue?vue&type=template&id=75aeffaa&scoped=true"
import script from "./arealoading.js?vue&type=script&lang=js&external"
export * from "./arealoading.js?vue&type=script&lang=js&external"
import style0 from "./arealoading.scss?vue&type=style&index=0&id=75aeffaa&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75aeffaa",
  null
  
)

export default component.exports