var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zelan-checkin"},[_c('Navigation',{attrs:{"title":"Check in"}}),_c('div',{staticClass:"zelan-checkin__body"},[_vm._m(0),(_vm.pageLoading)?_c('div',{staticStyle:{"height":"400rem","position":"relative"}},[_c('AreaLoading')],1):_c('div',{staticClass:"zelan-checkin__list"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:item.id,staticClass:"zelan-checkin__list-item",class:{
          'zelan-checkin__list-item--isvalidate': _vm.isValidate(item),
          'zelan-checkin__list-item--ischecked': _vm.isChecked(item),
          'zelan-checkin__list-item--isready': _vm.isReady(item)
        },style:({
          'margin-right': (index === 3 || index === 6) ? '0' : '40rem',
          'width': index === 6 ? (229 * 2 + 40) + 'rem' : '229rem'
        })},[_c('div',{staticClass:"zelan-checkin__list-item-top"},[_c('div',{staticClass:"zelan-checkin__list-item-topleft"},[_vm._v("Day "+_vm._s(index + 1))]),_c('div',{staticClass:"zelan-checkin__list-item-gemwrap"})]),_c('p',{staticClass:"zelan-checkin__list-item-count"},[_vm._v("+"+_vm._s(item.gemCount))])])}),0),(!_vm.pageLoading)?_c('div',{staticStyle:{"padding-top":"50rem","padding-left":"45rem","padding-right":"45rem"}},[_c('Button',{attrs:{"size":"medium"},on:{"click":_vm.checkin}},[_vm._v("Check in")])],1):_vm._e()]),_c('Dialog',{ref:"installAppTips",attrs:{"title":"Kind Tips","htmlContent":true,"footerButtons":[
      {
        id: 'download',
        name: `Download App`,
        color: '#e4e5e7',
        bgColor: '#5a64e9'
      },
      {
        id: 'notnow',
        name: 'Give up',
        color: '#e4e5e7',
        bgColor: '#383a42'
      },
    ]},on:{"onClickMask":_vm.giveUpInstall,"onClickButton":_vm.clickInstallAppTipsButton}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" Please go to the "),_c('span',[_vm._v("Zelan app")]),_vm._v(" to complete this operation. ")])]),_c('OpenApp',{attrs:{"bottom":50}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zelan-checkin__top"},[_c('div',{staticClass:"zelan-checkin__top-inner"},[_c('p',{staticClass:"zelan-checkin__top-text"},[_vm._v("LOG IN AND")]),_c('p',{staticClass:"zelan-checkin__top-text"},[_vm._v("DISTRIBUTE DIAMONDS")])]),_c('div',{staticClass:"zelan-checkin__top-fl"})])
}]

export { render, staticRenderFns }