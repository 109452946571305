import { mapGetters } from 'vuex';
import { report } from '@/api/report';
import Navigation from '@/components/Navigation/Navigation.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';
import TaskItem from '@/components/TaskItem/TaskItem.vue';

export default {
	name: 'Task',

	data() {
		return {
			taskList: [
				{
					id: 'checkin',
					name: 'Checkin',
					description: 'Log in every day to get diamonds.'
				},

				{
					id: 'first_recharge',
					name: 'First Recharge',
					description: 'Recharge for the first time. You can get an additional 110 diamond rewards.'
				},

				{
					id: 'download',
					name: 'Download App',
					description: 'Download Zelan App and log into the app for the first time. You can get 20 diamond rewards.'
				}
			],
		};
	},

	computed: {
    ...mapGetters(['inviteCode']),
  },

	methods: {
		clickTask(opts) {
			const inviteCode = this.inviteCode || '';

			if (opts.id === 'checkin') {
				this.$router.push({
          path: `/checkin`,
          query: {
            invite_code: inviteCode,
          }
        });
        report({
		      event_type: 'click',
		      event_value: 'task_checkin'
		    });
			}

			if (opts.id === 'download') {
				this.$router.push({
          path: `/download`,
          query: {
            invite_code: inviteCode,
          }
        });
        report({
		      event_type: 'click',
		      event_value: 'task_download'
		    });
			}

			if (opts.id === 'first_recharge') {
				this.$router.push({
          path: `/recharge`,
          query: {
            invite_code: inviteCode,
          }
        });
        report({
		      event_type: 'click',
		      event_value: 'task_recharge'
		    });
			}
		}
	},

	activated() {
		report({
      event_type: 'view',
      event_value: 'task'
    });
	},

	components: {
		Navigation,
		OpenApp,
		TaskItem
	}
};
