import AreaLoading from '@/components/AreaLoading/AreaLoading.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import Tab from '@/components/Tab/Tab.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import OpenApp from '@/components/OpenApp/OpenApp.vue';

export default {
	name: 'Order',
	components: {
		AreaLoading,
		Navigation,
		Tab,
		Dialog,
		OpenApp
	},

	data() {
		return {};
	}
};